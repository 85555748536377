<template>
    <div class="box">
        <div class="box-header">
            <h4>POS机信息</h4>
        </div>
        <el-form
            v-if="formModel"
            :on-loading="loading"
            ref="formModel"
            label-width="130px"
            label-suffix="："
        >
            <el-form-item label="品牌标语">
                <el-input
                    style="width:500px;"
                    v-model="formModel.slogan_title"
                    placeholder=""
                >
                </el-input>
            </el-form-item>

            <el-form-item label="品牌LOGO">
                <logo
                    :avatar="formModel.logo"
                    @on-upload-success="handleLogoSuccess"
                /><span class="label">(1366*768)</span>
            </el-form-item>

            <el-form-item label="背景图">
                <bg
                    :avatar="formModel.background"
                    @on-upload-success="handleBgSuccess"
                /><span class="label">(340*90)</span>
            </el-form-item>

            <el-form-item label="小票logo">
                <xp
                    :avatar="formModel.ticket_logo"
                    @on-upload-success="handleXPSuccess"
                />
                <span class="label">(291*291)</span>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    size="medium"
                    :loading="submitLoading"
                    @click="postGo"
                >保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import upload from '@admin/components/uploaders/Uploader'
import PosService from '@admin/services/PosService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'PosSetting',
  data () {
    return {
      submitLoading: false,
      formModel: null,
      loading: true
    }
  },

  components: {
    logo: upload,
    bg: upload,
    xp: upload
  },

  async created () {
    const { data } = await flatry(PosService.createSetting())
    if (data) {
      if (data.data.id) {
        this.formModel = data.data
      } else {
        this.formModel = {}
      }
    }
    this.loading = false
  },

  methods: {
    handleBgSuccess (url) {
      this.formModel.background = url
    },
    handleLogoSuccess (url) {
      this.formModel.logo = url
    },
    handleXPSuccess (url) {
      this.formModel.ticket_logo = url
    },
    async postGo () {
      this.submitLoading = true

      const { data } = await flatry(PosService.createSetting(this.formModel))
      if (data) {
        this.$message.success(data.msg)
      }
      this.submitLoading = false
    }
  }
}
</script>
<style lang='scss' scoped>
.label{
  position: relative;
  top:-20px;
  color:#cacaca;
}
</style>
